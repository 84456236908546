import axios from 'axios';
import * as config from './conf.json';

export default {



    getGames(filters = {}, filterType = null, filterValue = null) {
        let url = new URL(`${config.app_url_dev}/api/games`);

        Object.entries(filters).forEach((filter) => {
            url.searchParams.set(filter[0], filter[1]);
        });

        if (filterType && filterValue) {
            url.searchParams.set(filterType, filterValue);
        } else if (filterValue === 'all') {
            url.searchParams.delete(filterType);
        }

        return this.request('get', url);
    },

    getGenres() {
        let url = new URL(`${config.app_url_dev}/api/genres`);
        return this.request('get', url);
    },

    getTypes() {
        let url = new URL(`${config.app_url_dev}/api/types`);
        return this.request('get', url);
    },

    getStatus() {
        let url = new URL(`${config.app_url_dev}/api/status`);
        return this.request('get', url);
    },

    getHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    },

    request(method, url) {
        const loader = document.querySelector('.js-loader');
        loader.style.display = 'block';
        return axios.request({
            method: method,
            url,
            headers: this.getHeaders(),
        }).then(res => {
            loader.style.display = 'none';
            return res.data;
        });
    }
}
