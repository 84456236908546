<template>
  <div class="game-calendar">
    <div class="btn-date-container">
      <button @click="getGames('<')">
       &lt; Précédent
      </button>
      <p class="main-date">{{ currentMonth }}</p>
      <button @click="getGames('>')">
        Suivant &gt;
      </button>
    </div>
    <div class="game-filters">
       <Filter
        :filters="platformFilters"
        :selectedFilters="filters"
        :title="'Plateformes'"
        v-on:filterBy="filterBy" />
      <div class="game-select-filters">
        <SelectFilter
          :options="typeFilters"
          v-on:filterBy="filterBy" />
        <SelectFilter
          :options="statusFilters"
          v-on:filterBy="filterBy" />
        <SelectFilter
          :options="genreFilters"
          v-on:filterBy="filterBy" />
      </div>
    </div>
    <div class="checkbox-filters">
      <fieldset :class="['input-checkbox', onlyHyped ? 'active' : '']">
        <label for="hype">
          <input type="checkbox" id="hype" name="hype" @click="filterOnlyHyped()">
          Only hyped
        </label>
      </fieldset>
      <fieldset :class="['input-checkbox', onlyFollows ? 'active' : '']">
        <label for="follows">
          <input type="checkbox" id="follows" name="follows" @click="filterOnlyFollows()">
          Only follows
        </label>
      </fieldset>
      <fieldset :class="['input-checkbox', onlyHyped ? 'active' : '']">
        <label for="with_video">
          <input type="checkbox" id="with_video" name="with_video" @click="filterWithVideo()">
          Only with video
        </label>
      </fieldset>
    </div>
    <ul>
      <GameCard v-for="game in games" :key="game.slug" :game="game"></GameCard>
    </ul>
  </div>
</template>

<script>
import api from '../api.js';
import moment from 'moment';
import Filter from './Filter.vue';
import SelectFilter from './SelectFilter.vue';
import GameCard from './GameCard.vue';

export default {
  name: 'Game Calendar',
  components: {
    GameCard,
    Filter,
    SelectFilter
  },
  data() {
    return {
      games: [],
      filters: {'startDate': moment().format('Y-MM-DD')},
      days: [],
      base_url_cover: '//images.igdb.com/igdb/image/upload/t_cover_big/',
      onlyHyped: false,
      onlyFollows: false,
      withVideo: false,
      platformFilters: {
        0: {
          value: '0',
          filterType:'platforms',
          filterName:'All platforms',
        },
        1: {
          value: 6,
          filterType:'platforms',
          filterName:'PC',
        },
        2: {
          value: 169,
          filterType:'platforms',
          filterName:'Xbox X|S',
        },
        3: {
          value: 167,
          filterType:'platforms',
          filterName:'PS5',
        },
        4: {
          value: 130,
          filterType:'platforms',
          filterName:'Switch',
        },
      },
    }
  },
  mounted() {
    this.getGames();
    Promise.all([this.getGenres(), this.getTypes(), this.getStatus()]);
  },
  computed: {
    currentMonth() {
      return moment( this.filters['startDate'], 'Y-MM-DD').format('MMMM Y');
    }
  },
  methods: {
    getGames(operator = '') {
      if (operator === '>') {
         this.filters['startDate'] = moment( this.filters['startDate'], 'Y-MM-DD').add(1, 'months').format('Y-MM-DD');
      }

      if (operator === '<') {
        this.filters['startDate'] = moment( this.filters['startDate'], 'Y-MM-DD').subtract(1, 'months').format('Y-MM-DD');
      }

      api.getGames(this.filters).then(data => {
        this.games = data.games;
        this.filters = data.filters;
      });
    },
    filterOnlyHyped() {
      this.onlyHyped = !this.onlyHyped;
      this.filterBy({ filterType: 'hype', value: (this.onlyHyped ? 'true' : 'false') });
    },
    filterOnlyFollows() {
      this.onlyFollows = !this.onlyFollows;
      this.filterBy({ filterType: 'follows', value: (this.onlyFollows ? 'true' : 'false') });
    },
    filterWithVideo() {
      this.withVideo = !this.withVideo;
      this.filterBy({ filterType: 'with_video', value: (this.withVideo ? 'true' : 'false') });
    },
    getGenres() {
      api.getGenres().then(data => {
        this.genreFilters = data.genres;
      });
    },
    getTypes() {
      api.getTypes().then(data => {
        this.typeFilters = data.types;
      });
    },
    getStatus() {
      api.getStatus().then(data => {
        this.statusFilters = data.status;
      });
    },
    filterBy(filter) {
      const { filterType, value } = filter;
      api.getGames(this.filters, filterType, value).then(data => {
        this.games = data.games;
        this.filters = data.filters;
      });

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox-filters {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
}

.input-checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 .5rem;
  cursor: pointer;
  background-color: #0f0f0f;
  border: 0;
  color: #fff;
}

.input-checkbox label,
.input-checkbox input {
  cursor: pointer;
}

.btn-date-container {
  display: flex;
  margin: 0 2rem 2.5rem;
  justify-content: space-between;
}

.btn-date-container button {
  cursor: pointer;
  margin: 0 1.5rem;
  padding: .5rem;
  border: 0;
  background-color: #292929;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  font-style: italic;
  text-transform: uppercase;
}

.main-date {
  margin: 0;
  font-size: 28px;
  color: #fff;
}

ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.game-filters {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}

.game-select-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
