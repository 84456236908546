<template>
  <div class="logo-container">
    <img class="logo" alt="Vue logo" src="./assets/logo.png">
  </div>
  <GameCalendar msg="Welcome to Your Vue.js App"/>
  <div class="overlay-loader js-loader">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
import GameCalendar from './components/GameCalendar.vue'

export default {
  name: 'App',
  components: {
    GameCalendar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 1.5rem;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: .5rem 1.5rem 2rem;
}

.overlay-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .25);
}

.lds-ring {
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
