<template>
    <select class="select" @change="onChange($event)">
        <option v-for="option in options" :key="option.value" :value="option.value">{{ option.filterName }}</option>
    </select>
</template>

<script>

export default {
  name: 'SelectFilter',
  props: {
      options: Object,
  },
  methods: {
      onChange($event) {
          this.$emit('filterBy', { filterType: this.options[0].filterType, value:$event.target.value });
      }
  }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.select {
    height: 42px;
    padding: 0 .5rem;
    margin: 0 .5rem;
    background-color: #292929;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
}
</style>
