<template>
    <div class="filter-container js-filter-container">
        <h2>{{ title }}</h2>

        <div class="slide-prev js-slide-prev" />
            <ul>
                <li class="filter__item"  v-for="filter in filters" :key="filter.value">
                    <button :class="['filter__btn', isSelected(filter.filterType, filter.value) ? 'active' : '']"
                        data-filter-value="{{ filter.value }}"
                        data-filter-type="{{ filter.filterType }}"
                        @click="$emit('filterBy', filter)">
                            {{ filter.filterName }}
                    </button>
                </li>
            </ul>
        <div class="slide-next js-slide-next" />
    </div>
</template>

<script>

export default {
  name: 'Filter',
  props: {
      filters: Object,
      selectedFilters: Object,
      title: String,
  },
  methods: {
      isSelected(type, value) {
         return Object.values(this.selectedFilters).includes(value) || (!Object.keys(this.selectedFilters).includes(type) && value === '0');
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
    margin: .5rem .5rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
    opacity: .8;
    color: #fff;
}

.filter-container {
    position: relative;
    width: 35%;
    margin-bottom: 1rem;
}

ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

li.filter__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

button.filter__btn {
    border: 0;
    opacity: .5;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
    font-style: italic;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
}

button.filter__btn.active {
    opacity: 1;
}

li.filter__item:not(:last-child)::after {
    content: "";
    display: inline-flex;
    width: 6px;
    height: 6px;
    margin: 0 1rem;
    border-radius: 50%;
    background-color: #fff;
}
</style>
