<template>
  <li class="game">
    <p class="game__date">{{ getReleaseDate(game.release_date) }}</p>
    <a class="game__link" target="_blank" :href="game.video_url ? game.video_url : game.url">
      <img class="game__cover" v-if="game.cover_url" :src="game.cover_url">
      <img class="game__cover" v-if="!game.cover_url" src="../assets/placeholder.png">
    </a>
    <a class="game__link" target="_blank" :href="game.url">
      <p class="game__title">{{ game.title }}</p>
    </a>
  </li>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Game Card',
  props: {
    game: Object,
  },
  methods: {
    getReleaseDate(date) {
      return moment.utc(date).format('DD/MM/Y');
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game {
  display: flex;
  position: relative;
  width: 150px;
  height: 244px;
  object-fit: cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 3rem .5rem;
}

.game__link {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.game__date {
  position: absolute;
  background-color: #292929;
  top: 0;
  left: 0;
  margin: 0 .5rem 0 0;
  padding: .25rem;
  font-size: 12px;
  color: white;
}

.game__cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game__title {
  position: absolute;
  background-color: #292929;
  border-top: 1px solid #fe103a;
  color: #fe103a;
  font-weight: 700;
  font-size: 13px;
  font-style: italic;
  text-transform: uppercase;
  width: calc(100% - .5rem);
  margin: 0;
  padding: .25rem;
}

.game__title:hover {
  color: #a70021;
}
</style>
